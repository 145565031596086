//
// Layout Config
//

// Root font Sizes
$root-font-size:                                    13px; // Root font size for desktop mode
$root-font-size-lg:                             	13px; // Root font size for tablet mode
$root-font-size-md:                             	12px; // Root font size for mobile mode

// Page  background Color
$page-bg: 											if(isDarkMode(), #151521, $gray-100) !default;

// Page background image height
$page-bg-image-height: (
	desktop: 350px,
	tablet-and-mobile: 200px
) !default;

$page-bg-image-height-lg: (
	desktop: 530px,
	tablet-and-mobile: 450px
) !default;

// Content Spacing
$content-spacing: (
	desktop: 30px, // Padding for desktop mode
	tablet-and-mobile: 15px // Padding for tablet and mobile modes
) !default;

// Header
$header-config: (
	// Desktop Mode
	desktop: (
		// Default Mode
		default: (
			height: 80px,
			min-height: 80px,
			border: if(isDarkMode(), 1px solid rgba(255, 255, 255, 0.08), 1px solid rgba($white, 0.1)), 
		),

		// Fixed Mode
		fixed: (
			height: 65px,
			z-index: 97,
			bg-color: $body-bg,
			box-shadow: if(isDarkMode(), none, 0px 10px 30px 0px rgba(82,63,105,0.08)),
			border: 0
		)
	),

	// Tablet & Mobile Modes
	tablet-and-mobile: (
		// Default Mode
		default: (
			height: 65px,
			border: if(isDarkMode(), 1px solid rgba(255, 255, 255, 0.08), 1px solid rgba($white, 0.1)), 
		),

		// Fixed Mode
		fixed: (
			z-index: 97,
			height: 65px,
			bg-color: $body-bg,
			box-shadow: if(isDarkMode(), none, 0px 10px 30px 0px rgba(82,63,105,0.08)),
			border: 0
		)
	)
) !default;

// Toolbar
$toolbar-config: (
	height: (
		desktop: 75px,
		tablet-and-mobile: 60px
	)
) !default;

// Aside
$aside-config: (
	z-index: 101,
	padding-x: 25px,
	menu-indention: 0.75rem,
	width: 265px
) !default;

.modal {
	z-index: 1300 !important;
}

.modal-dialog {
	margin: 6.2rem auto !important;
}

.table-bordered {
	border: 1px solid #eff2f5;
}

.error-color {
	color: #f1416c;
}

.pseudoLink {
	color: #009ef7;
}

.pseudoLink:hover {
	color: #006dab;
	cursor: pointer;
}

// Begin Удаление стрелок в инпуте типа Number для добавления IMEI в список доверенных
.addImei input[type="number"]::-webkit-outer-spin-button,
.addImei input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.addImei input[type="number"] {
	-moz-appearance: textfield;
}
.addImei input[type="number"]:hover,
.addImei input[type="number"]:focus {
	-moz-appearance: number-input;
}
// End Удаление стрелок

// Begin Выбор диапазона дат
.nice-dates .date-range input {
	display: inline-block;
	text-align: center;
}

.nice-dates-day_month {
	display: none
}

.nice-dates-week-header_day {
	font-size: 0.8rem !important;
}

.nice-dates-day:before {
	background-color: #009ef7;
}

.nice-dates-day:before, .nice-dates-day:after {
	border-radius: 10px;
}

.nice-dates-popover {
	padding: 0 5px 5px;
	max-width: 200px;
}
// End Выбора диапазона дат

.detailPageDateRangeLabel {
	color: #7E8299;
	font-weight: 500;
	font-size: 1.075rem;
	margin-left: 10px;
	line-height: 1.1;
}

.-focused {
	background: #ceebfb !important;
}

.modal-dialog-scrollable .modal-content {
	max-height: 90% !important;
}

.tooltipPopup {
	font-size: 1rem;
	margin: 5px;
}

// File form control
.file-form-control::-webkit-file-upload-button {
	visibility: hidden;
}
.file-form-control::before {
	content: '';
	display: inline-block;
	outline: none;
	-webkit-user-select: none;
	cursor: pointer;
	margin-left: -133px;
}
.file-form-control:active {
	outline: 0;
}

input.nice-dates-report {
	width: 170px;
	padding: 10px;
	margin-right: 10px;
}

input.nice-dates-detail {
	width: 100px;
	padding: 7px;
	margin-right: 10px;
}

.progressBar {
	margin-top: 5px;
}

.progressBar .progress {
	height: unset;
	font-size: unset;
}

.progressBar .progress-bar{
	padding: 3px;
}

.progressBar .initialProgressBar.progress .progress-bar{
	color: #5E6278;
	background: #F5F8FA;
}

.progressBar .animatedProgressBar.progress .progress-bar, .progressBar .finalProgressBar.progress .progress-bar{
	text-align: right;
	padding-right: 5px;
}
/*
Styles for hardcoded download button
 */
.ExcelExport-MuiButtonBase-root-MuiButton-root {
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	background-color: transparent;
	outline: 0;
	border: 0;
	margin: 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	-moz-appearance: none;
	-webkit-appearance: none;
	text-decoration: none;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 500;
	font-size: 0.8125rem;
	line-height: 1.75;
	letter-spacing: 0.02857em;
	text-transform: uppercase;
	min-width: 64px;
	padding: 4px 5px;
	border-radius: 4px;
	-webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	color: #1976d2;
}

.ExcelExport-MuiButton-startIcon {
	display: inherit;
	margin-right: 8px;
	margin-left: -2px;
}

.ExcelExport-MuiSvgIcon-root {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 1em;
	height: 1em;
	display: inline-block;
	fill: currentColor;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	font-size: 18px;
}

.ExcelExport-MuiTouchRipple-root {
	-webkit-user-select: none;
	user-select: none;
	width: 1em;
	height: 1em;
	display: inline-block;
	fill: currentColor;
	flex-shrink: 0;
	transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	font-size: 18px;
}

/* End of download button styles */

input.simCardListPageUploadInput {
	border: none;
	margin-left: 50px;
	font-weight: 500;
	font-size: 13px;
	color: #7E8299;
	border-bottom: 1px solid #e1e1e1;
	border-radius: 0;
	padding-bottom: 8px;
	padding-right: 30px;
}

.simCardListPageUploadInputContainer {
	width: 300px;
	display: flex;
	align-items: center;
	margin-bottom: 11px;
}